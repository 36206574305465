<template>
  <va-create-layout>
    <test-features-form :title="title" :item="item"></test-features-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
