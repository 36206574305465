<template>
  <v-card :icon="resource.icon" :title="title">
    <va-list :filters="filters" disable-global-search disable-export disable-create>
      <template v-slot:actions>
        <v-tooltip bottom v-if="hasCompanyFilter">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon v-on="on" :to="companyServiceLink">
              <v-icon size="1.2rem">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add service</span>
        </v-tooltip>
      </template>
      <va-data-table :fields="fields">
        <template v-slot:[`item.actions`]="{ item }">
          <translation-button
            v-if="item.service_type !== 'App\\Models\\Utility'"
            :entity-id="item.id"
            app-class="App\Models\ContractsService"
            icon="mdi-format-align-bottom"
            :translation-level="getTranslationLevel('title')"
            :tooltip-text="`Edit ${$t('translation_level_names.title')} translation`"
          />
          <translation-button
            v-if="item.service_type !== 'App\\Models\\Utility'"
            :entity-id="item.id"
            app-class="App\Models\ContractsService"
            icon="mdi-format-align-left"
            :translation-level="getTranslationLevel('long_description')"
            :tooltip-text="`Edit ${$t('translation_level_names.long_description')} translation`"
          />
        </template>
        <template v-slot:[`field.service_type`]="{ value }">
          <v-chip>
            {{ $t(`app_class_names.${value}`) }}
          </v-chip>
        </template>
      </va-data-table>
    </va-list>
  </v-card>
</template>

<script>
import translationLevelMixin from "@/mixins/translationLevelMixin";
import TranslationButton from "@/components/TranslationButton";
export default {
  props: ["resource", "title"],
  components: { TranslationButton },
  data() {
    return {
      filters: [
        {
          source: "service_type",
          type: "select",
          attributes: {
            reference: "service-types",
            itemText: item => this.$t(`app_class_names.${item.class}`),
            itemValue: "class",
            multiple: true
          }
        },
        {
          source: "contract_id",
          type: "autocomplete",
          attributes: {
            reference: "companies"
          }
        }
      ],
      fields: [
        "service_type",
        {
          source: "csable.title",
          label: "Title"
        },
        {
          source: "contract",
          type: "reference",
          attributes: { reference: "companies", action: "show", chip: true, itemText: "name" }
        },
        {
          source: "is_active",
          type: "boolean"
        },
        { source: "activation_date", type: "date" }
      ]
    };
  },
  methods: {
    async fetchTranslationLevels() {
      const res = await this.$store.dispatch("translation-levels/getList");
      this.translationLevels = res.data;
    },
    getTranslationLevel(level) {
      return this.translationLevels.find(l => l.name == level);
    }
  },
  computed: {
    hasCompanyFilter() {
      return this.$route.query.filter && this.$route.query.filter.includes("contract_id");
    },
    companyServiceLink() {
      const filter = JSON.parse(this.$route.query.filter);
      return `/companies-services/create?contract_id=${filter.contract_id}`;
    }
  },
  mounted() {
    this.fetchTranslationLevels();
  },
  mixins: [translationLevelMixin]
};
</script>
