<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col sm="6">
        <v-card>
          <v-card-title>
            {{ title }}
          </v-card-title>
          <v-card-text>
            <va-text-input source="name"></va-text-input>
            <va-text-input source="slug"></va-text-input>
            <va-select-input
              source="industry_id"
              item-text="name"
              reference="industries"
            ></va-select-input>
            <va-select-input source="size_id" item-text="label" reference="sizes"></va-select-input>
            <va-array-input source="blacklistedDomains" label="Blacklisted Domains" v-slot="props">
              <v-row>
                <v-col>
                  <va-text-input v-bind="props" source="domain" label="Domain"></va-text-input>
                </v-col>
              </v-row>
            </va-array-input>
            <va-number-input source="annual_subscription" :step="0.01" :min="0"></va-number-input>
            <va-number-input source="licenses" :min="0"></va-number-input>
            <va-select-input
              source="hr_functionality_id"
              item-text="name"
              reference="hr-functionalities"
            ></va-select-input>
            <va-select-input
              source="locales"
              reference="locales"
              item-text="locale"
              multiple
            ></va-select-input>
            <va-boolean-input source="is_prospect" :value="model.is_prospect"></va-boolean-input>
            <va-boolean-input source="trial" :value="model.trial"></va-boolean-input>
            <va-boolean-input
              source="show_all_services"
              :value="model.show_all_services"
            ></va-boolean-input>
            <va-boolean-input source="is_disabled" :value="model.is_disabled"></va-boolean-input>
            <va-boolean-input
              source="closed_community"
              :value="model.closed_community"
            ></va-boolean-input>
            <va-save-button>Save</va-save-button>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
import setDefaultValuesMixin from "@/mixins/setDefaultValuesMixin";

export default {
  props: ["id", "title", "item"],
  data() {
    return {
      model: {}
    };
  },
  mounted() {
    this.setBoolean("is_prospect", false);
    this.setBoolean("trial", false);
    this.setBoolean("show_all_services", true);
    this.setBoolean("is_disabled", false);
    this.setBoolean("closed_community", false);
  },
  mixins: [setDefaultValuesMixin]
};
</script>
