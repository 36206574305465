<template>
  <v-card :icon="resource.icon" :title="title">
    <va-list :filters="filters" disable-global-search disable-export>
      <va-data-table :fields="fields" >
        <template v-slot:[`item.actions`]="{ item }">
          <translation-button
            :entity-id="item.entity_id"
            :app-class="item.entity_type"
            icon="mdi-pencil"
            :translation-level="item.translations_levels"
            tooltip-text="Edit"
          />
        </template>
      </va-data-table>
    </va-list>
  </v-card>
</template>

<script>
import TranslationButton from "@/components/TranslationButton";
export default {
  components: { TranslationButton },
  props: ["resource", "title"],
  data() {
    return {
      filters: [],
      fields: [
        "id",
        "entity_type",
        {
          source: "translatable.name",
          label: "Entity",
        },
        {
          source: "locale",
          type: "reference",
          attributes: { reference: "locales", chip: true, action: "edit", itemText: "locale"}
        },
        {
          source: "translations_levels.name",
          type: "text"
        },
        "value"
      ]
    };
  },
};
</script>
