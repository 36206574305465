<template>
  <v-form ref="form" @submit.prevent="validate">

    <!-- <v-checkbox v-model="form.remember" :label="$t('auth.remember')"></v-checkbox> -->

    <div class="text-center">
      <v-btn :loading="loading" color="primary" large type="submit" text rounded>{{
        $t("auth.sign_in")
      }}</v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        username: null,
        password: null,
        remember: false
      },
      errorMessages: {},
      loading: false
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login"
    }),
    async validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        try {
          await this.login(this.form);
        } catch (e) {
          if (e.errors) {
            this.errorMessages = e.errors;
            return;
          }

          this.errorMessages = { email: [e.message] };
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style scoped>
#login-page {
  background-color: var(--v-primary-lighten5);
}
</style>
