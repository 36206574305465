import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lastPage: null
  },
  mutations: {
    setLastPage: (state, page) => {
      state.lastPage = page;
    }
  },
  actions: {},
  getters: {
    getLastPage: state => state.lastPage
  },
  modules: {}
});
