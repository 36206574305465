<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col sm="6">
        <v-card>
          <v-card-title>
            {{ title }}
          </v-card-title>
          <v-card-text>
            <va-autocomplete-input
              source="contract_id"
              reference="companies"
            ></va-autocomplete-input>
            <va-select-input
              source="service_type"
              item-value="class"
              reference="service-types"
              @input="onTypeSelect"
              @change="onTypeSelect"
            ></va-select-input>
            <va-autocomplete-input
              v-if="selectedService !== ''"
              source="remote_service_id"
              item-text="title"
              item-value="id"
              :reference="selectedService"
              :key="selectedService"
            ></va-autocomplete-input>
            <va-date-input source="activation_date" v-model="date"></va-date-input>
            <va-number-input source="duration" :step="1"></va-number-input>
            <va-boolean-input source="is_active" :value="model.is_active"></va-boolean-input>
            <va-boolean-input
              source="user_activation"
              :value="model.user_activation"
            ></va-boolean-input>
            <va-boolean-input
              source="has_hr_invites"
              :value="model.has_hr_invites"
            ></va-boolean-input>
            <va-save-button></va-save-button>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
import classConversionMixin from "@/mixins/classConversionMixin";
import setDefaultValuesMixin from "@/mixins/setDefaultValuesMixin";

export default {
  props: ["id", "title", "item"],
  data() {
    return {
      model: {
        contract_id: this.$route.query.contract_id ? +this.$route.query.contract_id : null
      },
      date: this.item ? this.item.activation_date : null,
      selectedService: ""
    };
  },
  mounted() {
    this.setBoolean("is_active", true);
    this.setBoolean("user_activation", true);
    this.setBoolean("has_hr_invites", false);
  },
  methods: {
    onTypeSelect(data) {
      if (data) {
        this.selectedService = this.convertClassToResource(data);
      }
    }
  },
  mixins: [classConversionMixin, setDefaultValuesMixin]
};
</script>
