<template>
  <va-edit-layout :actions="['list']">
    <media-form :id="id" :title="title" :item="item"></media-form>
  </va-edit-layout>
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
