/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
  {
    icon: "mdi-view-dashboard",
    text: i18n.t("menu.dashboard"),
    link: "/"
  },
  { divider: true },
  admin.getResourceLink("companies"),
  admin.getResourceLink("companies-services"),
  admin.getResourceLink("companies-test-features"),
  admin.getResourceLink("utilities"),
  admin.getResourceLink("industries"),
  admin.getResourceLink("hr-functionalities"),
  admin.getResourceLink("sizes"),
  admin.getResourceLink("locales"),
  admin.getResourceLink("media"),
  admin.getResourceLink("translations"),
  admin.getResourceLink("translation-levels"),
  admin.getResourceLink("test-features"),
  admin.getResourceLink("service-types")
];
