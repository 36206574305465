<template>
  <v-card :icon="resource.icon" :title="title">
    <va-list :filters="filters" disable-global-search disable-export>
      <va-data-table :fields="fields" />
    </va-list>
  </v-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [],
      fields: [
        {
          source: "contract",
          type: "reference",
          attributes: { reference: "companies", action: "show", chip: true, itemText: "name" }
        }
      ]
    };
  }
};
</script>
