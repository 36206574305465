export default {
  methods: {
    convertClassToResource(className) {
      switch (className) {
        case `App\\Models\\Stream`:
          return "streams";
        case `App\\Models\\Program`:
          return "programs";
        case `App\\Models\\Utility`:
          return "utilities";
        case `App\\Models\\Contract`:
          return "companies";
        case `App\\Models\\ContractsService`:
          return "companies-services";
      }
    }
  }
};
