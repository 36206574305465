<template>
  <va-create-layout>
    <companies-test-features-form :title="title" :item="item"></companies-test-features-form>
  </va-create-layout>
</template>

<script>
export default {
  props: ["title", "item"],
};
</script>
