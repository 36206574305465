<template>
  <va-form :id="id" :item="item">
    <v-row justify="center">
      <v-col sm="6">
        <v-card>
          <v-card-title>
            {{ title }}
          </v-card-title>
          <v-card-text>
            <va-text-input source="label"></va-text-input>
            <va-text-input source="class"></va-text-input>
            <va-save-button></va-save-button>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
};
</script>
