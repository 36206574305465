<template>
  <v-card :icon="resource.icon" :title="title">
    <va-list :filters="filters" disable-global-search disable-export disable-create>
      <template v-slot:actions>
        <v-tooltip bottom v-if="hasCompanyFilter">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon v-on="on" :to="testFeatureLink">
              <v-icon size="1.2rem">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add test feature</span>
        </v-tooltip>
      </template>
      <va-data-table :fields="fields"></va-data-table>
    </va-list>
  </v-card>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
        {
          source: "contract_id",
          type: "autocomplete",
          attributes: {
            reference: "companies"
          }
        }
      ],
      fields: [
        {
          source: "contract",
          type: "reference",
          attributes: { reference: "companies", action: "edit", chip: true, itemText: "name" }
        },
        {
          source: "test_feature",
          type: "reference",
          attributes: { reference: "test-features", action: "edit", chip: true, itemText: "title" }
        }
      ]
    };
  },
  computed: {
    hasCompanyFilter() {
      return this.$route.query.filter && this.$route.query.filter.includes("contract_id");
    },
    testFeatureLink() {
      const filter = JSON.parse(this.$route.query.filter);
      return `/companies-test-features/create?contract_id=${filter.contract_id}`;
    }
  }
};
</script>
