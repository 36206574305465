import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
  CHECK_ERROR,
  GET_NAME,
  GET_EMAIL,
  GET_PERMISSIONS
} from "./actions";

export default (axios, params = {}) => {
  params = {
    routes: {
      csrf: "/api/csrf-cookie",
      login: "/login",
      logout: "api/logout",
      user: "/api/user"
    },
    getName: u => u.name,
    getEmail: u => u.email,
    getPermissions: u => u.roles,
    ...params
  };

  let { routes, getName, getEmail, getPermissions } = params;

  return {
    [LOGIN]: async () => {
      await axios.get(routes.csrf);
      window.location.replace(`${process.env.VUE_APP_BACKEND_URL}/api/auth/redirect`);
      return Promise.resolve();
    },
    [LOGOUT]: async () => {
      await axios.get(routes.logout);
      return Promise.resolve();
    },
    [CHECK_AUTH]: async () => {
      const res = await axios.get(routes.csrf);
      axios.defaults.headers["X-XSRF-TOKEN"] = res.data;

      let response = await axios.get(routes.user);

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      return response;
    },
    [CHECK_ERROR]: ({ status }) => {
      if (status === 401 || status === 403) {
        window.location.replace(
          `${process.env.VUE_APP_LOGIN_URL}/login?intendedUrl=${window.location.href}`
        );
      }
      return Promise.resolve();
    },
    [GET_NAME]: user => getName(user),
    [GET_EMAIL]: user => getEmail(user),
    [GET_PERMISSIONS]: user => getPermissions(user)
  };
};
