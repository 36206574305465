import Vue from "vue";
import AdminLayout from "@/layouts/Admin";
import Dashboard from "@/views/Dashboard";
import TranslationForm from "@/views/TranslationForm";
import Error from "@/views/Error";
import i18n from "@/i18n";

/**
 * Error component
 */
Vue.component("Error", Error);

export default {
  path: "",
  component: AdminLayout,
  meta: {
    title: i18n.t("routes.home")
  },
  children: [
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        title: i18n.t("routes.dashboard")
      }
    },
    {
      path: "/translation-form",
      name: "translation",
      component: TranslationForm,
      meta: {
        title: "Translation Form"
      }
    },
    {
      path: "/with-alcohol-comma-blackjack-and-prostitutes",
      name: "SecretView",
      component: () => import("../views/SecretView.vue")
    },
    {
      path: "*",
      component: Error,
      meta: {
        title: i18n.t("routes.not_found")
      }
    },
  ]
};
