<template>
  <v-tooltip bottom v-if="isTranslatable">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" icon v-on="on" @click="goToTranslation">
        <v-icon size="1.2rem">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "TranslationButton",
  props: {
    entityId: Number,
    appClass: {
      type: String,
      required: true
    },
    translationLevel: Object,
    icon: {
      type: String,
      required: true
    },
    tooltipText: String
  },
  methods: {
    goToTranslation() {
      this.$store.commit("setLastPage", this.$route.name);
      this.$router.push(this.translationLink);
    }
  },
  computed: {
    isTranslatable() {
      return (
        this.appClass === "App\\Models\\ContractsService" ||
        this.appClass === "App\\Models\\Contract"
      );
    },
    translationLink() {
      let url = `/translation-form?entity_type=${this.appClass}&entity_id=${this.entityId}`;
      if (this.translationLevel) {
        url = `${url}&translations_level_id=${this.translationLevel.id}`;
      }
      return url;
    }
  }
};
</script>
