<template>
  <v-card :icon="resource.icon" :title="title">
    <va-list :filters="filters" disableGlobalSearch>
      <va-data-table :fields="fields">
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" icon v-on="on" :to="getMediaLink(item)">
                <v-icon size="1.2rem">mdi-image-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ hasMedia(item) ? "Edit" : "Add" }} logo</span>
          </v-tooltip>
          <translation-button
            :entity-id="item.id"
            app-class="App\Models\Contract"
            icon="mdi-email-outline"
            :translation-level="getTranslationLevel('email_label')"
            :tooltip-text="`Edit ${$t('translation_level_names.email_label')} translation`"
          />
          <translation-button
            :entity-id="item.id"
            app-class="App\Models\Contract"
            icon="mdi-google-classroom"
            tooltip-text="Add shared room translation"
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" icon v-on="on" :to="getServicesLink(item)">
                <v-icon size="1.2rem">mdi-file-tree-outline</v-icon>
              </v-btn>
            </template>
            <span>Show services</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" icon v-on="on" :to="getTestFeatureLink(item)">
                <v-icon size="1.2rem">mdi-test-tube</v-icon>
              </v-btn>
            </template>
            <span>Show test features</span>
          </v-tooltip>
        </template>
      </va-data-table>
    </va-list>
  </v-card>
</template>

<script>
import translationLevelMixin from "@/mixins/translationLevelMixin";
import TranslationButton from "@/components/TranslationButton";
export default {
  props: ["resource", "title"],
  components: { TranslationButton },
  data() {
    return {
      filters: [
        "name",
        "slug",
        {
          source: "industry_id",
          type: "select",
          attributes: { reference: "industries", itemText: "name", multiple: true }
        },
        {
          source: "size",
          type: "select",
          attributes: { reference: "sizes", multiple: true }
        },
        {
          source: "hr_functionality_id",
          type: "select",
          attributes: { reference: "hr-functionalities", multiple: true, itemText: "name" }
        }
      ],
      fields: [
        "id",
        "name",
        "slug",
        { source: "workforce_count", type: "number", sortable: true },
        {
          source: "industry",
          type: "reference",
          attributes: { reference: "industries", action: "edit", chip: true, itemText: "name" }
        },
        {
          source: "size",
          type: "reference",
          attributes: { reference: "sizes", action: "edit", chip: true, itemText: "label" }
        },
        {
          source: "hr_functionality",
          type: "reference",
          attributes: {
            reference: "hr-functionalities",
            action: "edit",
            chip: true,
            itemText: "name"
          }
        },
        {
          source: "annual_subscription",
          type: "number",
          sortable: true
        },
        {
          source: "locales",
          type: "array",
          attributes: { color: "success", column: true, itemText: "locale" }
        }
      ]
    };
  },
  methods: {
    getMediaLink(item) {
      return this.hasMedia(item)
        ? `/media/${item.media[0].id}/edit`
        : `/media/create/?contractId=${item.id}`;
    },
    hasMedia(item) {
      return item.media.length > 0;
    },
    getServicesLink(item) {
      return `/companies-services/?filter={"contract_id":${item.id}}`;
    },
    getTestFeatureLink(item) {
      return `/companies-test-features/?filter={"contract_id":${item.id}}`;
    }
  },
  mixins: [translationLevelMixin]
};
</script>
