<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="6">
        <v-card :loading="loading">
          <template slot="progress">
            <v-progress-linear color="primary" height="10" indeterminate> </v-progress-linear>
          </template>
          <v-card-title class="d-block">
            <h5 v-if="company">Company: {{ company.name }}</h5>
            <h5 v-if="translationLevel">Translation for: {{ translationLevelName }}</h5>
            <h5 v-if="entityName">Reference: {{ entityName }}</h5>
          </v-card-title>
          <v-card-text>
            <v-form ref="form" @submit.prevent="validate" v-if="currentTranslations.length > 0">
            <v-autocomplete
                v-if="!hasTranslationLevel"
                v-model="queryParameters.translations_level_id"
                :items="translationLevels"
                dense
                filled
                label="Translation level"
                item-text="name"
                item-value="id"
              ></v-autocomplete>
              <v-text-field
                v-for="t in currentTranslations"
                :key="t.locale.locale"
                v-model="formData[t.locale.id]"
                :label="`${t.locale.locale} translation`"
              ></v-text-field>
              <div class="d-flex justify-end mt-3">
                <v-btn color="primary" type="submit" :loading="formLoading">Save</v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "TranslationForm",
  data(){
    return {
      formData: {},
      currentTranslations: [],
      company: null,
      entityName: null,
      requiredParameters: ['entity_type', 'entity_id', 'translations_level_id'],
      translationLevel: null,
      queryParameters: {},
      loading: true,
      formLoading: false,
      translationLevels: []
    }
  },
  mounted(){
    this.fetchTranslations();
  },
  methods:{
    ...mapActions({
      getList: 'api/getList'
    }),
    async fetchTranslations(){
      let queryString = "";
      this.requiredParameters.map((parameter, index) => {
        const queryParameter = this.$route.query[parameter];
        if (queryParameter) {
          const isLast = index < this.requiredParameters.length -1;
          this.queryParameters[parameter] = queryParameter;
          queryString += `${parameter}=${queryParameter}${isLast ? "&" : ""}`;
        }
      });

      try {
        if (!this.hasTranslationLevel) {
          const translationLevels = await this.getList({
            resource: "translation-levels"
          });
          this.translationLevels = translationLevels.data;
        }

        const res = await this.$admin.http.get(`/api/translations/show?${queryString}`);
        this.loading = false;
        this.currentTranslations = res.data.translations;
        this.currentTranslations.map(t => {
          this.formData[t.locale.id] = t.translation;
        });

        this.company = res.data.company;
        this.translationLevel = res.data.translationLevel;
        this.entityName = res.data.entityName;
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
    async submitTranslations(){
      this.formLoading = true;
      let data = [];
      Object.keys(this.formData).map(key => {
        data.push({
          ...this.queryParameters,
          locale_id: key,
          value: this.formData[key]
        });
      });

      try{
        await this.$admin.http.post(`/api/translations`, { data: data });
        this.formLoading = false;
        const lastPage = this.$store.getters["getLastPage"];
        this.$router.push({name: lastPage ? lastPage : 'dashboard'});
      }catch(err){
        console.log(err);
        this.formLoading = false;
      }
    },
    async validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        try {
          this.submitTranslations();
        } catch (e) {
          if (e.errors) {
            this.errorMessages = e.errors;
            return;
          }
        } finally {
          this.loading = false;
        }
      }else{
        console.log('validateError');
      }
    }
  },
  computed:{
    hasTranslationLevel(){
      return !!this.$route.query['translations_level_id'];
    },
    translationLevelName(){
      const translationLevelName = this.translationLevel.name;
      if (translationLevelName.includes("shared_room")) {
        const splitText = translationLevelName.split("_");
        const id = splitText[splitText.length - 1];
        return `${this.$t("translation_level_names.shared_room")} - ${id}`;
      } else {
        return this.$t(`translation_level_names.${translationLevelName}`);
      }
    }
  }
}
</script>
