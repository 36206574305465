<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col sm="6">
        <v-card>
          <v-card-title v-if="title">
            {{ title }}
          </v-card-title>
          <v-card-text>
            <va-select-input
              source="locale_id"
              reference="locales"
              item-text="locale"
            ></va-select-input>
            <va-select-input
              source="translations_level_id"
              reference="translation-levels"
              item-text="name"
            ></va-select-input>
            <va-text-input source="value"></va-text-input>
            <va-save-button></va-save-button>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      model: {
        entity_type: this.$route.query.entity_type ? this.$route.query.entity_type : null,
        entity_id: this.$route.query.entity_id ? +this.$route.query.entity_id : null,
        translations_level_id: this.$route.query.translations_level_id ? +this.$route.query.translations_level_id : null,
      },
    };
  }
};
</script>
