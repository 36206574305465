export default {
  data() {
    return {
      translationLevels: []
    };
  },
  mounted() {
    this.fetchTranslationLevels();
  },
  methods: {
    async fetchTranslationLevels() {
      const res = await this.$store.dispatch("translation-levels/getList");
      this.translationLevels = res.data;
    },
    getTranslationLevel(level){
      return this.translationLevels.find(l => l.name == level);
    }
  },
};
