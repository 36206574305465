export default [
  {
    name: "companies",
    icon: "mdi-office-building-outline",
    label: "name",
    except: ["show"],
    include: { expand: ["industry", "size", "hrFunctionality", "locales", "media", "translations"] },
  },
  { name: "industries", except: ["show"], icon: "mdi-briefcase-outline" },
  { name: "sizes", except: ["show"], icon: "mdi-image-size-select-small" },
  { name: "locales", except: ["show"], icon: "mdi-translate" },
  {
    name: "companies-services",
    include: { expand: ["company", "csable", "translations"] },
    except: ["show"],
    icon: "mdi-book-cog-outline"
  },
  { name: "service-types", except: ["show"], icon: "mdi-chemical-weapon" },
  {
    name: "hr-functionalities",
    except: ["show", "export"],
    icon: "mdi-badge-account-outline"
  },
  { name: "streams", icon: "mdi-file-tree-outline" },
  { name: "programs", icon: "mdi-certificate-outline" },
  { name: "utilities", except: ["show"], icon: "mdi-toolbox-outline" },
  {
    name: "media",
    except: ["show", "clone"],
    include: { expand: ["company"] },
    icon: "mdi-image-outline"
  },
  {
    name: "translations",
    except: ["show", "edit"],
    include: { expand: ["locale", "translatable", "translationsLevels"] },
    icon: "mdi-web"
  },
  {
    name: "translation-levels",
    except: ["show"],
    icon: "mdi-webpack"
  },
  {
    name: "test-features",
    except: ["show"],
    icon: "mdi-test-tube"
  },
  {
    name: "companies-test-features",
    except: ["show"],
    include: { expand: ["company", "testFeature"] },
    icon: "mdi-factory"
  },
]
