<template>
  <va-form
    :id="id"
    :item="item"
    v-model="model"
    @saved="$router.push({ name: 'companies_list' })"
    disable-redirect
  >
    <v-row justify="center">
      <v-col sm="6">
        <v-card>
          <v-card-title>
            {{ title }}
          </v-card-title>
          <v-card-text>
            <va-autocomplete-input
              source="contract_id"
              reference="companies"
              :class="item ? 'd-none' : ''"
            ></va-autocomplete-input>
            <div class="mb-3" v-if="item">
              <h3 class="mb-3">Company</h3>
              <va-reference-field
                source="contract_id"
                :item="item"
                reference="companies"
                fetch
                action="edit"
              >
              </va-reference-field>
            </div>
            <div v-if="model.oldFile">
              <h3 class="mb-3">Logo</h3>
              <va-image-field
                :item="model.oldFile"
                item-value="file.thumbnail"
                src="file.thumbnail"
              />
              <div class="d-flex">
                <v-spacer />
                <v-btn @click="test" color="error">Clear</v-btn>
              </div>
            </div>
            <va-file-input
              source="file"
              label="Logo"
              :clearable="false"
              preview
              v-if="!model.oldFile"
            >
              <div></div>
            </va-file-input>
            <va-save-button v-if="!model.oldFile"></va-save-button>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      model: {
        contract_id: this.$route.query.contractId ? +this.$route.query.contractId : null,
        oldFile: this.item
      }
    };
  },
  methods: {
    test() {
      this.model.oldFile = null;
    }
  }
};
</script>
