<template>
  <va-form :id="id" :item="item" v-model="model">
    <v-row justify="center">
      <v-col sm="6">
        <v-card>
          <template v-slot:heading>
            <div class="display-2">
              {{ title }}
            </div>
          </template>
          <v-card-text>
            <va-autocomplete-input
              source="contract_id"
              reference="companies"
            ></va-autocomplete-input>
            <va-select-input
              source="test_feature_id"
              reference="test-features"
              item-text="title"
            ></va-select-input>
            <va-save-button></va-save-button>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data() {
    return {
      model: {
        contract_id: this.$route.query.contract_id ? +this.$route.query.contract_id : null
      }
    };
  }
};
</script>
