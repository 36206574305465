<template>
  <v-app>
    <v-container fluid fill-height id="login-page">
      <v-layout align-center justify-center>
        <v-flex :style="{ 'max-width': '350px' }">
          <v-card class="text-center">
            <v-card-text>
              <h2 class="mb-4">Lifeed Admin</h2>

              <img src="../assets/logo.svg" width="80" height="80" />

              <transition name="fade" mode="out-in">
                <router-view></router-view>
              </transition>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  mounted() {
    window.location.replace(
      `${process.env.VUE_APP_LOGIN_URL}/login?intendedUrl=${window.location.href}`
    );
  }
};
</script>

<style scoped>
#login-page {
  background-color: #34495e;
}
</style>
